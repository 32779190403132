// TODO : use this if prod server is ready
import config from "src/config";
import _ from "lodash";

const api = config.SCAN_API;
const lcdApi = config.LCD_API;
const rpcApi = config.RPC_API;
const contractApi = config.CONTRACT_DEPLOY_API;

export default Object.freeze({
	DEFAULT_ARRAY: [],
	API_BINANCE_DEX: "https://www.binance.org/en/trade",
	API_BINANCE_ACCELERATED: ["https://dex-asiapacific.binance.org/api/v1", "https://dex-atlantic.binance.org/api/v1", "https://dex-european.binance.org/api/v1"],
	BINANCE_API_ENDPOINTS: {
		TX: tx => `/tx/${tx}?format=json`,
	},
	API_COINGECKO: {
		PRICE: (ids, currency) => `https://price.market.orai.io/simple/price?ids=${ids}&vs_currencies=${currency}`,
	},
	COIN_ID: "oraichain-token",
	AIRI_ID: "airight",
	DENOM: "orai",
	GRAVITY: "gravity",
	ORAIB: "oraib",
	DENOM_ORAI: "orai",
	MORE: "more",
	TABLE: {
		PAGE_SIZE: 20,
	},
	MIN_MAINTAINANCE: 200,
	NUM: {
		BASE_MULT: 100000000,
		DEFAULT_DECIMALS: 8,
		COSMOS_DECIMAL: 1000000,
		PAGE_SIZE: 20,
		SPARE_PAGE_CNT: 2, //  amount of pages to preload in pagination
		BINACE_API_ROWS_LIMIT: 1000, //  max rows binance api allows
		BINANCE_API_PAGES_LIMIT: 100, //  max page binance api allows
		REAL_TIME_DELAY_MS: 2000, //  real-time refetch interval(for indexedPagination)
		DASH_REAL_TIME_DELAY_MS: 3000, //  dashboard refetch interval
		DETAIL_REAL_TIME_DELAY_MS: 7000, // detail display refetch interval
		ACCOUNT_REFETCH_INTERVAL_MS: 5000, // TODO : currently not used
		ASSET_REFETCH_INTERVAL_MS: 80000,
		ASSET_REFETCH_PRICE_INTERVAL_MS: 80000,
		BASIC_DATA_FETCH_INTERVAL_MS: 30000,
		TX_TIMEOUT_HEIGHT: 200,
	},
	ASSET: {
		NAME_SEARCH_PROPERTY: ["asset", "mappedAsset", "name"],
		ORDER_COMPARE: ["mappedAsset", "marketCap", "price", "supply"],
	},
	GET_LOGO_LINK: symbol =>
		_.isString(symbol) ? `https://raw.githubusercontent.com/cosmostation/cosmostation_token_resource/master/thumnail/${_.split(symbol)[0]}}.png` : "",
	API_BASE: api,
	API_CONTRACT_DEPLOY: contractApi,
	LCD_API_BASE: lcdApi,
	RPC_API_BASE: rpcApi,
	API: {
		STATUS: "/status",
		BLOCKLIST: "/blocks",
		BLOCK: "/block",
		TXS_BLOCK: "/txs-block",
		ACCOUNT: "/account",
		ACCOUNTS: "/accounts",
		TOPHOLDERS: "/token",
		ACCOUNT_TXS: acc => `/account/txs/${acc}?page=1&rows=20`,
		TXLIST: "/txs",
		TX: "/txs",
		ORDERS: "/orders",
		ASSET_IMAGES: "/assets-images?page=1&rows=1000",
		ASSETS: "/assets?page=1&rows=1000",
		ASSETS_BEP8: "/assets/mini-tokens?page=1&rows=1000",
		ASSET_PRICES: "/assets?page=1&rows=1000&only_price=true",
		ASSET: "/asset?asset=",
		ASSET_TXS: "/assets/txs?page=1&rows=20&txAsset=",
		ASSET_HOLDERS: "/asset-holders?&page=1&rows=20&asset=",
		TOP_ASSETS: "/market/coin/list",
		CHARTS: "/stats/assets/chart",
		FEE: "/fees",
		VALIDATORS: "/validators",
		UNBONDINGS: "/account/unbonding",
		TXS_ACCOUNT: "/txs-account",
		TXS_ROYALTY: "/txs-royalty",
		TXS_CONTRACT: "/txs-contract",
		ORAICHAIN_INFO: "/oraichain_info",
		ACCOUNT_COINS: "/account/coins",
		ACCOUNT_BALANCE: "/account/balance",
		VALIDATOR: "/validator",
		DELEGATOR: "/delegator",
		PROPOSED_BLOCKS: "/proposed-blocks",
		MISSED_BLOCKS: "/validator/missed-block",
		DELEGATIONS: "/delegations",
		PROPOSALS: "proposals",
		PROPOSAL_STATUS: "proposal/status",
		PROPOSALS_TRANSACTIONS: "/proposals/transactions",
		VALIDATOR_ANALYTICS: "/validator-analytics",
		PROPOSAL_VOTES: "/proposal/votes",
		PROPOSALS_TOTAL_TXS: "/proposals/total_txs",
		WALLET: {
			CLAIM_REWARD: "/wallet/claim_reward",
			WITHDRAW: "/wallet/withdraw",
			DELEGATOR: "/wallet/delegator",
			VALIDATOR: "/wallet/validator",
		},
		MIN_FEE: "min_gas",
		ORACLE_SCRIPTS_REQUEST: "oracle-scripts/request",
		ORACLE_SCRIPTS: "oracle-scripts",
		REQUESTS: "/ai_requests",
		SMART_CONTRACT: "/smart_contract",
		ORACLE_REQUESTS_DATA_SOURCES: "/oracle_request/data_source",
		ORACLE_REQUESTS_TEST_CASE: "/oracle_request/test_case",
		REQUESTS_REPORTS: "/oracle_request/reports",
		REQUESTS_RESULTS: "/oracle_request/results",
		REQUESTS_REPORTS_TEST_CASE_RESULTS: "/requests_reports/detail/tc_results",
		TEST_CASES: "/test_cases",
		FIREBASE_FCM_TOKEN: "/firebase_token",
		SMART_CONTRACTS: "/smart_contracts",
		REQUESTS_VALIDATOR: "/validator/requests",
		ORACLE_REQUESTS: "/oracle_requests",
		ORACLE_REPORT: "/oracle_report",
		EXPORT_DATA: "/account/txs_csv",
		EXPORT_CW20_TRANSACTION: "/ow20_smart_contracts/txs_csv",
		IBC_TOKENS: "/ibc/tokens",
		UPLOAD_IMAGES_VALIDATORS: "/validator/update-image",
		GET_IMAGES_VALIDATORS: "/validator-detail",
		OW20_SMART_CONTRACTS: "/ow20_smart_contracts",
		NFT_TXS: "/nft_transaction",
		OW20_CONTRACT: "/ow20_contract",
		OW20_TOKEN: "/ow20_tokens",
		WASM_CODE: "/wasm_code",
		WASM_CODE_BY_ID: "/smart_contracts_by_code_id",
		UPLOAD_SCHEMA: "/upload-schema",
	},
	LCD_API: {
		DATA_SOURCES: "/data_sources",
		DATA_SOURCE_DETAIL: "/provider/datasource",
		TEST_CASES: "/provider/testcases",
		ORACLE_SCRIPTS: "/provider/oscripts",
		ORACLE_SCRIPT_DETAIL: "/provider/oscript",
		ACCOUNT_DETAIL: "/cosmos/auth/v1beta1",
		BALANCES: "/cosmos/bank/v1beta1/balances",
		CLAIM_REWARD: "/cosmos/distribution/v1beta1/delegators",
		DELEGATION: "/cosmos/staking/v1beta1/delegations",
		AI_REQUEST_DATA: "/cosmos/tx/v1beta1/txs",
		TXS: "/cosmos/tx/v1beta1/txs",
		WASM: "/cosmwasm/wasm/v1/contract",
		DENOM_TRACES: "ibc/applications/transfer/v1beta1/denom_traces",
		COMMUNITY_POOL: "/cosmos/distribution/v1beta1/community_pool",
	},
	NETWORK: {
		COSMOS: "cosmoshub-3",
		IRIS: "irishub",
		KAVA: "kava-3",
		BINANCE: "binance",
	},
	PREFIX: {
		COSMOS: "cosmos",
		IRIS: "iris",
		KAVA: "kava",
		ORAI_ADDR: "orai",
		ORAI_OPER: "oraivaloper",
		ORAI_CONS: "oraivalcons",
	},
	LINK: {
		GOOGLE: "https://play.google.com/store/apps/details?id=wannabit.io.cosmostaion",
		IOS: "https://apple.co/2IAM3Xm",
		WEB: "https://wallet.cosmostation.io",
		COSMOSTATION: "https://www.cosmostation.io/",
		BINANCEDEX: "https://www.binance.org/",
		COINGECKO_ORAI: "https://www.coingecko.com/en/coins/oraichain-token",
	},
	HD_PATH: {
		DEFAULT: "m/44'/118'/0'/0/0",
		EXAMPLE: "44/118/0/0/0",
		PRODUCTION: "",
		STAGING: "",
	},
	PATH_CONTRACT: {
		LIST: "/contracts",
		UPLOAD_SCHEMA: "/contracts/upload-schema",
		GET_NONCE: "/contracts/nonce/",
	},
	MENU: [
		{
			display: "DASHBOARD",
			route: "/",
			primary: true,
		},
		{
			display: "VALIDATOR",
			route: "/validators",
		},
		{
			display: "BLOCKS",
			route: "/blocks",
		},
		{
			display: "TRANSACTIONS",
			route: "/txs",
		},
	],
	REQUEST: {
		LIMIT: 10, // Number of records per page
		TIMEOUT: 10000,
		PROPOSED_BLOCKS_LIMIT: 9,
		REQUESTS_LIMIT: 12,
	},
	DOMAIN: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}/`,
	PATH: {
		BLOCKLIST: "/blocks",
		VALIDATORS: "/validators",
		TXLIST: "/txs",
		ACCOUNT: "/account",
		DATA_SOURCES: "/data-sources",
		TEST_CASES: "/test-cases",
		ORACLE_SCRIPTS: "/oracle-scripts",
		PROPOSALS: "/proposals",
		REQUESTS: "/ai_requests",
		REQUESTS_REPORTS: "/requests_reports",
		SMART_CONTRACT: "/smart-contract",
		SMART_CONTRACTS: "/smart-contracts",
		WASM_CODE: "/wasm-code",
		ORACLE_REQUEST: "/ai-request",
		EXPORT_DATA: "/export-data",
		VERIFIED_CONTRACT: "/verified-contract",
	},
	ADDRESS_PREFIX: {
		VALIDATOR: "oraivaloper1",
		ACCOUNT: "orai",
		OSMOS_ACCOUNT: "osmo",
		COSMOS_ACCOUNT: "cosmos",
		TERRA_ACCOUNT: "terra",
	},
	ANIMATION: {
		DELAY: 100,
	},
	ROYALTY_DECIMAL_POINT_PERCENT: 10000000,

	VALID_ORIGINS: ["https://testnet-wallet.web.app", "https://api.wallet.orai.io", "http://localhost:3001", "https://prerelease.wallet.orai.io"],

	PROPOSALS_OPTIONS: {
		UNBONDING_TIME: "UnbondingTime",
		VOTING_PERIOD: "votingparams",
		DEPOSIT_PARAMS: "depositparams",
		COMMUNITY_TAX: "communitytax",
		INFLATION_MIN: "InflationMin",
		INFLATION_MAX: "InflationMax",
		TEXT_PROPOSAL: "TextProposal",
		UPDATE_ADMIN_PROPOSAL: "UpdateAdminProposal",
		COMMUNITY_POOL_SPEND_PROPOSAL: "communityPoolSpendProposal",
	},

	VOTING_PERIOD_OPTIONS: {
		VOTING_DAY: "voting_period_day",
		VOTING_TIME: "voting_period_time",
	},

	RESULT_STATUS: {
		SUCCESS: "success",
		FAILURE: "failure",
		PENDING: "pending",
	},
	INSTALL_KEPLR_FIRST: "You need to install OWallet or Keplr to continue.",
	AIRI_CONTRACT_ADDRESS: "orai10ldgzued6zjp0mkqwsv2mux3ml50l97c74x8sg",
	ORAIX_CONTRACT_ADDRESS: "orai1lus0f0rhx8s03gdllx2n6vhkmf0536dv57wfge",
});
